body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.myRoot {
    max-width: 800px;
    margin: 120px auto 0px auto;
    text-align: left;
}

.loginAlert {
    position: fixed !important;
    top: 80px;
    left: 20px;
}

.ovpnConfig {
    height: 350px !important;
    font-family: monospace;
}

.navbar{
    border-bottom: 3px solid #e49455;
}

#logoutBtn {
 vertical-align: 2px;
    margin-left: 10px;
}

.responsiveImage {
    max-width: 100%;
    height: auto;
}